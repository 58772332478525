
import Vue from 'vue';
import { login, logout, getInfo, systemLog } from '@/api/user';
import { getToken, setToken, removeToken } from '@/utils/auth';
import { setLocalStorage, getLocalStorage } from '@/utils/index';
import watermark from 'watermark-dom';
// import { resetRouter } from '@/router';

const getDefaultState = () => {
  return {
    token: getToken(),
    name: '',
    avatar: '',
    doctorUpdateStatus: 0,
    userName: '',
    userId: '',
    institutionName: '',
    activeLabel: 'persona',
    oldDialogStatus: false,
    dialogStatus: false,
    doctorData: {},
    isOce: 0,
    seed: '',
    isFirst: 0,
    step: 0,
    isDM: false,
    learningType: '',
    editPermission: true
  };
};

const state = getDefaultState();

const mutations = {
  SETUSERDATA: (state, data) => {
    sessionStorage.setItem('userName', data.user_name);
    sessionStorage.setItem('userId', data.user_id);
    state.userName = data.user_name;
    state.userId = data.user_id;
    // state.institutionName = data.institution_name;
    state.doctorData = data;
    setLocalStorage('doctorData', JSON.stringify(data));
  },
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_EDIT_PERSSION: (state, data) => {
    state.editPermission = data;
    setLocalStorage('edit_permission', data);
  },
  SET_DIALOGSTATUS(state, data) {
    // if (data) {
    //   document.documentElement.style.overflowY = 'hidden';
    // } else {
    //   document.documentElement.style.overflowY = 'scroll';
    // }
    state.oldDialogStatus = data;
    state.dialogStatus = data;
  },
  SET_UPDATEDOCTOR(state, data) {
    state.doctorUpdateStatus++;
  },
  SET_OCE: (state, data) => {
    state.isOce = data;
    setLocalStorage('isOce', data);
  },
  SET_SEED: (state, data) => {
    state.seed = data;
    Vue.prototype.seed = data;
  },
  SET_FIRST: (state, data) => {
    state.isFirst = data;
    setLocalStorage('isFirst', data);
  },
  SET_STEP: (state, data) => {
    state.step = data;
    setLocalStorage('step', data);
  },
  SET_LEARNINGTYPE: (state, data) => {
    state.learningType = data;
  },
  GET_TERR_TYPE: (state, data) => {
    // MR是代表，DM是地区经理
    let territory_type = getLocalStorage('territory_type');
    state.isDM = territory_type === 'DM';
  },
  REDIRECT_ERROR: (state) => {
    this.$router.push({
      path: '/error',
      query: {}
    });
  },
  SETWATERMARKET: (state, data) => { // 设置水印
    let userName = getLocalStorage('user_name');
    let userId = getLocalStorage('user_id');
    // let loginTime = getLocalStorage('login_time');

    let watermark_txt = userName + '' + userId + '';
    // console.info(watermark_txt);
    watermark.load({
      // watermark_prefix: 'app',
      // watermark_parent_node: 'app',
      watermark_txt: watermark_txt,
      watermark_alpha: 0.05,
      watermark_rows: 100,
      watermark_cols: 100,
      watermark_width: 280,
      watermark_height: 100
    });
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  },
  SET_ACTIVE_LABEL: (state, data) => {
    state.activeLabel = data;
  }
};

const actions = {
  // 弹窗控制
  setLearningType({ commit }, data) {
    commit('SET_LEARNINGTYPE', data);
  },
  setDialogStatus({ commit }, data) {
    commit('SET_DIALOGSTATUS', data);
  },
  setUpdateDoctor({ commit }, data) {
    commit('SET_UPDATEDOCTOR', data);
  },
  setOCE({ commit }, data) {
    commit('SET_OCE', data);
  },
  setEDITPERSSION({ commit }, data) {
    commit('SET_EDIT_PERSSION', data);
  },
  setFIRST({ commit }, data) {
    commit('SET_FIRST', data);
  },
  setSTEP({ commit }, data) {
    commit('SET_STEP', data);
  },
  setSeed({ commit }, data) {
    commit('SET_SEED', data.substr(0, 24));
  },
  setWaterMarket({ commit }, data) {
    commit('SETWATERMARKET');
  },
  getTerrType({ commit }, data) {
    commit('GET_TERR_TYPE');
  },
  redirectError({ commit }, data) {
    commit('REDIRECT_ERROR');
  },
  // userSystemLog({ commit }, obj) {
  //   let data = {
  //     user_id: getLocalStorage('user_id'),
  //     page: obj.page,
  //     page_uri: obj.page_uri,
  //     log_time_start: new Date(),
  //     page_param: obj.page_param,
  //     session: getLocalStorage('sessionId') ? getLocalStorage('sessionId') : ''
  //   };
  //   return new Promise((resolve, reject) => {
  //     systemLog(data).then(response => {
  //       const { data } = response;
  //       resolve();
  //     }).catch(error => {
  //       reject(error);
  //     });
  //   });
  // },
  // user login
  login({ commit }, userInfo) {
    const { username, password } = userInfo;
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password: password }).then(response => {
        const { data } = response;
        commit('SET_TOKEN', data.token);
        setToken(data.token);
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
  },
  settingUserData({ commit }, data) {
    commit('SETUSERDATA', data);
  }
  // user login
  // login({ commit }, userInfo) {},

  // get user info
  // getInfo({ commit, state }) {},

  // user logout
  // logout({ commit, state }) {},

  // remove token
  // resetToken({ commit }) {}
};

export default {
  // namespaced: true,
  state,
  mutations,
  actions
};

