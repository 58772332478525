import Vue from 'vue';
import VueI18n from 'vue-i18n';
// import enUS from 'vant/lib/locale/lang/en-US'
// import zhCN from 'vant/lib/locale/lang/zh-CN'

import cenUS from 'cube-ui/src/locale/lang/en-US';
import czhCN from 'cube-ui/src/locale/lang/zh-CN';

import zh from './lang/zh/index.js';
import en from './lang/en/index.js';

Vue.use(VueI18n);
const i18n = new VueI18n({
  // locale: 'EN', // window.localStorage.getItem('language') || 'ZH',
  locale: window.localStorage.getItem('language') || 'ZH',
  messages: {
    // chinese package
    ZH: {
      // ...zhCN,
      ...czhCN,
      ...zh
    },
    // english package
    EN: {
      // ...enUS,
      ...cenUS,
      ...en
    }
  }
});

export default i18n;
