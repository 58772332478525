const state = () => {
  return {
    doctorObj: {}
  };
};

const mutations = {
  setDoctorData(state, value) {
    state.doctorObj = value;
  }
};

const actions = {
  setDoctorData(context, params) {
    context.commit('setDoctorData', params);
  }
};

export default {
  state,
  mutations,
  actions
};
