export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result;

  const later = () => {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp;

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      }
    }
  };

  return (...args) => {
    context = this;
    timestamp = +new Date();
    const callNow = immediate && !timeout;
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait);
    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }

    return result;
  };
}
export function settPageData(pageArr, pageSize) {
  let arrLength = pageArr.length;
  let num = pageSize;
  let index = 0;
  let newArr = [];
  for (let i = 0; i < arrLength; i++) {
    if (i % num === 0 && i !== 0) {
      newArr.push(pageArr.slice(index, i));
      index = i;
    }
    if ((i + 1) === arrLength) {
      newArr.push(pageArr.slice(index, i + 1));
    }
  }

  return newArr;
}
// adopt from https://ruby-china.org/topics/29026
export function chineseToEnglish(c) { // 中文转拼音
  var idx = -1;
  var MAP = 'ABCDEFGHJKLMNOPQRSTWXYZ';
  var boundaryChar = '驁簿錯鵽樲鰒餜靃攟鬠纙鞪黁漚曝裠鶸蜶籜鶩鑂韻糳';
  if (!String.prototype.localeCompare) {
    throw Error('String.prototype.localeCompare not supported.');
  }
  if (/[^\u4e00-\u9fa5]/.test(c)) {
    return c;
  }
  for (var i = 0; i < boundaryChar.length; i++) {
    if (boundaryChar[i].localeCompare(c, 'zh-CN-u-co-pinyin') >= 0) {
      idx = i;
      break;
    }
  }
  return MAP[idx];
}

export function getFirstUpperChar(str) {
  let string = String(str);
  var c = string[0];
  if (/[^\u4e00-\u9fa5]/.test(c)) {
    return c.toUpperCase();
  } else {
    return chineseToEnglish(c);
  }
}

export function reverseByKey(key) {
  return (a, b) => {
    const val1 = a[key];
    const val2 = b[key];
    return val2 - val1;
  };
}

export function LightenDarkenColor(col, amt) {
  var usePound = false;
  if (col[0] === '#') {
    col = col.slice(1);
    usePound = true;
  }
  var num = parseInt(col, 16);
  var r = (num >> 16) + amt;
  if (r > 255) {
    r = 255;
  } else if (r < 0) {
    r = 0;
  }
  var b = ((num >> 8) & 0x00FF) + amt;
  if (b > 255) {
    b = 255;
  } else if (b < 0) {
    b = 0;
  }
  var g = (num & 0x0000FF) + amt;
  if (g > 255) {
    g = 255;
  } else if (g < 0) {
    g = 0;
  }
  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
}

export function findIndex(ary, fn) {
  if (ary.findIndex) {
    return ary.findIndex(fn);
  }
  /* istanbul ignore next */
  let index = -1;
  /* istanbul ignore next */
  ary.some((item, i, ary) => {
    const ret = fn.call(this, item, i, ary);
    if (ret) {
      index = i;
      return ret;
    }
  });
  /* istanbul ignore next */
  return index;
}
export function setLocalStorage(key, value) {
  window.localStorage.setItem(key, value);
}

export function getLocalStorage(key) {
  return window.localStorage.getItem(key);
}

export function removeLocalStorage(key) {
  return window.localStorage.removeItem(key);
}

export function removeAllLocalStorage() {
  return window.localStorage.clear();
}
export function filterNull(arr) {
  let tArr = [];
  arr.forEach(ele => {
    if (ele) {
      tArr.push(ele);
    }
  });
  return tArr;
}

export function getURLParams(url) {
  let paramResult = {};
  if (url.indexOf('?')) {
    let paramContent = url.split('?')[1]; // 获取?之后的参数字符串
    let paramsArray = [];
    if (paramContent) {
      paramsArray = paramContent.split('&');// 参数字符串分割为数组
      // 遍历数组，拿到json对象
      paramsArray.forEach((item, index, paramsArray) => {
        paramResult[paramsArray[index].split('=')[0]] = paramsArray[index].split('=')[1];
      });
    }
  }
  return paramResult;
}
export function getMimeTypeBySuffix(suffix) {
  let mimeType = '';
  switch (suffix.toLowerCase()) {
    case 'html': mimeType = 'text/html';
      break;
    case 'txt': mimeType = 'text/plain';
      break;
    case 'gif': mimeType = 'image/gif';
      break;
    case 'jpeg': mimeType = 'image/jpeg';
      break;
    case 'jpg': mimeType = 'image/jpeg';
      break;
    case 'png': mimeType = 'image/png';
      break;
    case 'mp4': mimeType = 'video/mp4';
      break;
    case 'mpg': mimeType = 'video/mpeg';
      break;
    case 'mpeg': mimeType = 'video/mpeg';
      break;
    case 'avi': mimeType = 'video/x-msvideo';
      break;
    case 'gz': mimeType = 'application/x-gzip';
      break;
    case 'tar': mimeType = 'application/x-tar';
      break;
    case 'doc': mimeType = 'application/msword';
      break;
    case 'docx': mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      break;
    case 'xls': mimeType = 'application/vnd.ms-excel';
      break;
    case 'xlsx': mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      break;
    case 'ppt': mimeType = 'application/vnd.ms-powerpoint';
      break;
    case 'pptx': mimeType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
      break;
    case 'gzip': mimeType = 'application/x-gzip';
      break;
    case 'zip': mimeType = 'application/zip';
      break;
    case '7zip': mimeType = 'application/zip';
      break;
    case 'rar': mimeType = 'application/rar';
      break;
    case 'pdf': mimeType = 'application/pdf';
      break;
  }
  return mimeType;
}
