
import axios from 'axios';
import { Notify } from 'vant';
import store from '@/store';
import { BASE_URL } from './constants.js';
import { getToken } from '@/utils/auth';
import * as crypto from 'crypto-js';
// create an axios instance
const service = axios.create({
  baseURL: BASE_URL, // url = base url + request url
  timeout: 600000 // request timeout
});

// request interceptor
service.interceptors.request.use(
  config => {
    // if (!config.url.includes('/api/login/auth') && !config.url.includes('/api/weixin/login') && !config.url.includes('/api/standard/OData/Users')) {
    //   let urlSplit = config.url.split('/standard/');
    //   let seed = getToken().seed.substr(0, 24);
    //   let ajaxData = crypto.TripleDES.encrypt(urlSplit[1],
    //     crypto.enc.Utf8.parse(seed), { mode: crypto.mode.ECB }).toString();
    //   let request = encodeURIComponent(ajaxData);
    //   // config.url = '/api/gateway/standard?request=' + request;
    // }
    // do something before request is sent
    if (getToken()) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['app_id'] = getToken().app_id;
      config.headers['device_id'] = getToken().device_id;
    }
    config.headers['Access-Control-Allow-Origin'] = '*';
    return config;
  },
  error => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data;
    const statusCode = [200, 201, 202];
    if (statusCode.includes(response.status)) {
      return response.data;
    } else {
      Notify({ type: 'danger', message: '网络错误' });
      return Promise.reject(response);
    }
  },
  error => {
    // Notify({ type: 'danger', message: '请求超时' });
    return Promise.reject(error);
  }
);

export default service;
