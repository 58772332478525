import Cookies from 'js-cookie';

export function getToken() {
  const token = {
    'seed': Cookies.get('seed'),
    'app_id': Cookies.get('app_id'),
    'device_id': Cookies.get('device_id')
  };
  return token;
}

export function setToken(token) {
  Cookies.set('seed', token.seed);
  Cookies.set('app_id', token.app_id);
  Cookies.set('device_id', token.device_id);
}

export function removeToken() {
  Cookies.remove('seed');
  Cookies.remove('app_id');
  Cookies.remove('device_id');
}
