<template>
  <div class="app-container">
    <router-view />
    <TabBar />
  </div>
</template>

<script>
import TabBar from '../hcp/components/tabBar.vue';
export default {
  name: 'Index',
  components: {
    TabBar,
  },
  data() {
    return {};
  },
  methods: {
  }
};
</script>
<style scoped lang="scss">
.app-container {
  height: 100%;
}
</style>
