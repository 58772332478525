import Vue from 'vue';
import './cube-ui';
import App from './App.vue';
import store from './store';
import router from '@/router/router';
import '@/styles/index.scss';
import {setLocalStorage, getLocalStorage} from '@/utils/index';
import i18n from './I18n';
import 'vant/lib/index.css';
import 'amfe-flexible'; // rem h5 适配
import _ from 'lodash';
import * as filters from './filters';
import VueLazyload from 'vue-lazyload';

import {library} from '@fortawesome/fontawesome-svg-core';
import {Dialog, Toast} from 'vant';
import VueCompositionAPI from '@vue/composition-api';
import {
  faArrowUp,
  faPlus,
  faQuestionCircle,
  faPaperPlane,
  faCommentDots,
  faEdit,
  faMicrophoneAlt,
  faMapMarkerAlt,
  faUser,
  faCircle,
  faPlayCircle,
  faCaretRight,
  faCaretDown,
  faCaretUp,
  faChevronCircleRight,
  faHospital,
  faUserTag,
  faTag,
  faStarHalfAlt,
  faUserMd,
  faHandsHelping,
  faThumbsUp,
  faThumbsDown,
  faFlag,
  faHandHoldingHeart,
  faStar,
  faUsers,
  faNewspaper,
  faBriefcaseMedical,
  faCalendarAlt,
  faGraduationCap,
  faClinicMedical,
  faList,
  faSyncAlt,
  faUserPlus,
  faLongArrowAltRight,
  faLongArrowAltDown,
  faChevronCircleDown,
  faChevronDown,
  faChevronUp,
  faCodeBranch,
  faTheaterMasks,
  faClipboardCheck,
  faMinusCircle,
  faTasks,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
library.add(
  faArrowUp,
  faPlus,
  faQuestionCircle,
  faPaperPlane,
  faCommentDots,
  faEdit,
  faMicrophoneAlt,
  faMapMarkerAlt,
  faUser,
  faCircle,
  faPlayCircle,
  faCaretRight,
  faCaretDown,
  faCaretUp,
  faChevronCircleRight,
  faHospital,
  faUserTag,
  faTag,
  faStarHalfAlt,
  faUserMd,
  faHandsHelping,
  faThumbsUp,
  faThumbsDown,
  faFlag,
  faHandHoldingHeart,
  faStar,
  faUsers,
  faNewspaper,
  faBriefcaseMedical,
  faCalendarAlt,
  faGraduationCap,
  faClinicMedical,
  faList,
  faSyncAlt,
  faUserPlus,
  faLongArrowAltRight,
  faLongArrowAltDown,
  faChevronCircleDown,
  faChevronDown,
  faChevronUp,
  faCodeBranch,
  faTheaterMasks,
  faClipboardCheck,
  faMinusCircle,
  faTasks,
);
// edit\comment-dots\paper-plane\hands-helping
Vue.component('font-awesome-icon', FontAwesomeIcon);

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});
Vue.prototype._ = _;
Vue.config.productionTip = false;
Vue.use(VueLazyload);
Vue.use(VueCompositionAPI);

Vue.directive('scroll', {
  inserted: function (el, binding) {
    let f = function (evt) {
      if (binding.value(evt, el)) {
        el.removeEventListener('scroll', f);
      }
    };
    el.addEventListener('scroll', f);
  },
});
// vant 组件按需引入Diglog v-modal不生效
Vue.use(Dialog);
Vue.use(Toast);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
