<template>
  <Tabbar v-model="active" :safe-area-inset-bottom="true" @change="changeTab">
    <TabbarItem icon="friends">医生</TabbarItem>
    <TabbarItem icon="chat">拜访</TabbarItem>
    <!-- <TabbarItem icon="star">建议</TabbarItem> -->
    <TabbarItem icon="graphic">报告</TabbarItem>
  </Tabbar>
</template>

<script>
import {Tabbar, TabbarItem} from 'vant';
import {getLocalStorage} from '@/utils/index';
export default {
  name: 'TabBar',
  components: {
    Tabbar,
    TabbarItem,
  },
  data() {
    return {
      active: 0,
    };
  },
  created() {
    const url = this.$route.name;
    console.log('url', url);
    switch (url) {
      case 'doctorListHome':
        this.active = 0;
        break;
      case 'visitListHome':
        this.active = 1;
        break;
      // case 'insightListHome':
      //   this.active = 2;
      //   break;
      default:
        break;
    }
  },
  methods: {
    changeTab(e) {
      switch (e) {
        case 0:
          this.$router.push({
            path: '/hcp/home',
            query: {
              user_id: getLocalStorage('user_id_key'),
            },
          });
          break;
        case 1:
          this.$router.push({
            path: '/hcp/visit-list',
          });
          break;
        // case 2:
        //   this.$router.push({
        //     path: '/hcp/insight-list',
        //   });
        //   break;
        case 2:
          this.$router.push({
            path: '/hcp/report',
          });
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="sass" scoped></style>
