import request from '@/utils/request';
import * as crypto from 'crypto-js';

export function auth(username) {
  let password = crypto.TripleDES.encrypt(
    crypto.MD5(username).toString(crypto.enc.Base64),
    crypto.enc.Utf8.parse('1A9A8M02L02I12I01G84S8O9'),
    {mode: crypto.mode.ECB},
  ).toString();
  return request({
    url: `/api/login/auth?uid=${encodeURIComponent(
      username,
    )}&password=${encodeURIComponent(password)}`,
    method: 'get',
    data: {},
  });
}
export function getTerrId(data) {
  return request({
    // &$expand=physician
    // url: `/api/standard/OData/VUserAlignments?$count=true&$filter=user_name eq '${data.user_name}'&$skip=0&$top=2`,
    url: `/api/standard/OData/Territories?$count=true&$filter=user_id eq '${data.user_id}'&$expand=user`, // and territory_name eq '${data.user_name}'
    method: 'get',
  });
}
export function login(data) {
  return request({
    // &$expand=physician
    // url: `/api/standard/OData/VUserAlignments?$count=true&$filter=user_name eq '${data.user_name}'&$skip=0&$top=2`,
    url: `/api/standard/OData/VUserAlignments?$count=true&$filter=user_id eq '${data.user_id}' and user_name eq '${data.user_name}'&$skip=0&$top=2&$expand=territory`,
    method: 'get',
    data,
  });
}
export function getUserRole(data) {
  return request({
    url: `/api/standard/OData/userRoles?$filter=user_id eq '${data.user_id}'&$expand=user,territory($select=territory_type, territory_id, user_name)`, // and territory_name eq '${data.user_name}'
    method: 'get',
  });
}
export function getUserTa(data) {
  return request({
    url: `/api/standard/OData/userTas?$filter=user_id eq '${data.user_id}'`,
    method: 'get',
  });
}

export function getUserByTerrId(territory_id) {
  return request({
    url: `/api/standard/OData/Territories?$filter=territory_id eq '${territory_id}'`,
    method: 'get',
  });
}

// 登录日志
export function loginLog(data) {
  return request({
    url: `/api/standard/LogUserLogins`,
    method: 'post',
    data,
  });
}
// 系统使用日志
// export function systemLog(data) {
//   return request({
//     url: `/api/standard/OData/LogUserActions`,
//     method: 'post',
//     data
//   });
// }

export function getInfo(token) {
  return request({
    url: '/api/standard/user/info',
    method: 'get',
    params: {token},
  });
}

export function logout() {
  return request({
    url: '/api/standard/user/logout',
    method: 'post',
  });
}
// 记录信息检索搜索日志
export function systemSearchLog(data) {
  return request({
    url: `/api/standard/LogUserRuleSearches`,
    method: 'post',
    data,
  });
}
// 微信登录
export function getInfoByCode(code) {
  return request({
    url: `/api/weixin/login?code=${code}`,
    method: 'get',
  });
}
export function getUserInfo(uid) {
  return request({
    url: `/api/standard/OData/Users?$filter=user_login_name eq '${uid}'`,
    method: 'get',
  });
}
// 埋点LogUserVisits

export function LogUserVisits(data) {
  return request({
    url: `/api/standard/OData/LogUserVisits`,
    method: 'post',
    data,
  });
}
