import { setLocalStorage, getLocalStorage } from '@/utils/index';
import _ from 'underscore';

export default {
  data() {
    return {
    };
  },
  computed: {
    step: {
      get() {
        return parseInt(this.$store.state.user.step) || parseInt(getLocalStorage('step'));
      },
      set() {}
    },
    isFirst() {
      return this.$store.state.user.isFirst || getLocalStorage('isFirst');
    }
  },
  methods: {
    getFirstStatusFn() {
      let isFirst = getLocalStorage('isFirst');
      let step = getLocalStorage('step');
      this.$store.dispatch('setFIRST', isFirst);
      this.$store.dispatch('setSTEP', step);
    },
    settingStep(step, status) {
      let stepObj = JSON.parse(getLocalStorage('stepObj'));
      stepObj['step' + step] = 1;
      let stepValues = _.values(stepObj);
      if (!stepValues.includes(0)) {
        this.$store.dispatch('setFIRST', 'no');
        this.$store.dispatch('setSTEP', -1);
      }
      setLocalStorage('stepObj', JSON.stringify(stepObj));
    },
    getStepStatus(step) {
      let stepObj = JSON.parse(getLocalStorage('stepObj'));
      return !parseInt(stepObj['step' + step]);
    }
  }
};
