import Vue from 'vue';
import Router from 'vue-router';
import index from '@/views/layout/index';
import kolLayout from '@/views/layout/kolLayout';
import {getLocalStorage, getURLParams, setLocalStorage} from '@/utils/index';
import store from '@/store';
import {getToken} from '@/utils/auth';
import {systemLog, LogUserVisits} from '@/api/user';
import * as moment from 'moment';

Vue.use(Router);

const routerMothods = ['push', 'replace'];
routerMothods.map((method) => {
  const VueRouterCall = Router.prototype[method];

  Router.prototype[method] = function (location, onComplete, onAbort) {
    if (onComplete || onAbort) {
      return VueRouterCall.call(this, location, onComplete, onAbort);
    }
    return VueRouterCall.call(this, location).catch((err) => {
      console.log({path: location, msg: err});
    });
  };
});

var router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/hcp',
      redirect: '/hcp/home',
      component: index,
      children: [
        {
          path: 'home',
          name: 'listHome',
          component: () =>
            import(/* webpackChunkName: "hcp" */ '@/views/hcp/home.vue'),
        },
        {
          path: 'department',
          name: 'departmentList',
          component: () =>
            import(
              /* webpackChunkName: "hcp" */ '@/views/hcp/departmentList.vue'
            ),
        },
        {
          path: 'list',
          name: 'list',
          component: () =>
            import(/* webpackChunkName: "hcp" */ '@/views/hcp/list.vue'),
        },
        {
          path: 'detail',
          name: 'hcp',
          component: () =>
            import(/* webpackChunkName: "hcp" */ '@/views/hcp/details.vue'),
        },
        {
          path: 'visit-list',
          name: 'visitListHome',
          component: () => import('@/views/hcp/visit-list.vue'),
        },
        {
          path: 'insight-list',
          name: 'insightListHome',
          component: () => import('@/views/kol/insight-list.vue'),
        },
        {
          path: 'visit-create',
          name: 'visitCreate',
          component: () => import('@/views/hcp/VisitCreate.vue'),
        },
        {
          path: 'report',
          name: 'reportHome',
          component: () => import('@/views/hcp/report.vue'),
        },
        {
          path: 'blank',
          name: 'blank',
          component: () =>
            import(/* webpackChunkName: "hcp" */ '@/views/blank.vue'),
        },
      ],
    },
    {
      path: '/doctor-select',
      name: 'doctorSelect',
      component: () => import('@/components/DoctorListSelect.vue'),
    },
    {
      path: '/kol',
      component: kolLayout,
      children: [
        // {
        //   path: 'doctor-select',
        //   name: 'doctorSelect',
        //   component: () => import('@/components/DoctorListSelect.vue'),
        // },
        {
          path: 'visit-create',
          name: 'visitCreate',
          component: () =>
            import(/* webpackChunkName: "hcp" */ '@/views/kol/VisitCreate.vue'),
        },
        {
          path: 'insight-create',
          name: 'InsightCreate',
          component: () =>
            import(
              /* webpackChunkName: "hcp" */ '@/views/kol/InsightCreate.vue'
            ),
        },
        {
          path: 'home',
          name: 'KolHome',
          component: () => import('@/views/kol/home.vue'),
        },
        {
          path: 'department',
          name: 'departmentList',
          component: () =>
            import(
              /* webpackChunkName: "hcp" */ '@/views/kol/departmentList.vue'
            ),
        },
        {
          path: 'list',
          name: 'list',
          component: () =>
            import(/* webpackChunkName: "hcp" */ '@/views/kol/list.vue'),
        },
        {
          path: 'doctor-list',
          name: 'doctorListHome',
          component: () => import('@/views/kol/home.vue'),
        },
        {
          path: 'detail',
          name: 'doctorDetailHome',
          component: () => import('@/views/kol/details.vue'),
        },
        {
          path: 'visit-list',
          name: 'visitListHome',
          component: () => import('@/views/kol/visit-list.vue'),
        },
        {
          path: 'insight-list',
          name: 'insightListHome',
          component: () => import('@/views/kol/insight-list.vue'),
        },
        {
          path: 'report',
          name: 'reportHome',
          component: () => import('@/views/kol/report.vue'),
        },
      ],
    },
    // { path: '/nba', name: 'nba', component: () => import(/* webpackChunkName: "nba" */ '@/views/nba/index.vue') },
    // { path: '/follow', redirect: '/nba' },
    {
      path: '/login',
      name: 'login',
      component: () =>
        import(/* webpackChunkName: "login" */ '@/views/login.vue'),
    },
    // { path: '/', name: 'login', component: () => import(/* webpackChunkName: "login" */ '@/views/login.vue') },
    {
      path: '/',
      name: 'loginInUrl',
      component: () =>
        import(/* webpackChunkName: "login" */ '@/views/loginInUrl.vue'),
    },
    {
      path: '/authorize',
      name: 'authorize',
      component: () =>
        import(/* webpackChunkName: "login" */ '@/views/authorize.vue'),
    },
    {
      path: '/error',
      name: 'error',
      component: () =>
        import(/* webpackChunkName: "login" */ '@/views/error.vue'),
    },
    {path: '*', redirect: '/'},
  ],
});

/**
 * @description check whether user login
 */
async function authCheck(to, from, next) {
  next();
  // let token = '1';// store.state.user.token;
  // // let user_id = getLocalStorage('user_id') ? getLocalStorage('user_id') : '';
  // let user_id = "";
  // if (window.location.href.indexOf('?') > -1) {
  //   let urlParams = getURLParams(window.location.href);
  //   if (urlParams.user_id) {
  //     user_id = urlParams.user_id;
  //     setLocalStorage("user_id", user_id);
  //   }
  // } else if (getLocalStorage("user_id")) {
  //   user_id = getLocalStorage("user_id");
  // }
  // let data = {
  //   user_id: user_id,
  //   page: to.path,
  //   page_uri: to.fullPath,
  //   log_time_start: new Date(),
  //   page_param: store.state.doctor.doctorObj.physician_name,
  //   session: getLocalStorage('sessionId') ? getLocalStorage('sessionId') : ''
  // };
  // if (getToken().device_id) {
  //   systemLog(data).then(res => {
  //   });
  // }
  // if (token) {
  //   let pathArr = ['/', '/login', '/error', '/hcp/detail', '/authorize'];
  //   if (pathArr.includes(to.path)) {
  //     next();
  //   } else {
  //     if (user_id) {
  //       next();
  //     } else {
  //       next('/error');
  //     }
  //   }
  //   // try {
  //   // } catch {
  //   // }
  // } else {
  //   // 白名单
  //   next();
  // }
}

/**
 * Navigation Guards - beforeEach
 * @description check auth from vuex user token. Whether auth limit by router meta permission
 */
router.beforeEach((to, from, next) => {
  const data = {
    user_id: getLocalStorage('user_id') ? getLocalStorage('user_id') : '',
    visit_time: moment().format('YYYY-MM-DD HH:mm:ss'),
    visit_page: to.path,
    visit_platform: 'mobile',
    visit_full_url: window.location.href,
  };
  LogUserVisits(data);
  authCheck(to, from, next);
});

export default router;
