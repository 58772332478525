import * as moment from 'moment';

function pluralize(time, label) {
  if (time === 1) {
    return time + label;
  }
  return time + label + 's';
}

export function timeAgo(time) {
  const between = Date.now() / 1000 - Number(time);
  if (between < 3600) {
    return pluralize(~~(between / 60), ' minute');
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), ' hour');
  } else {
    return pluralize(~~(between / 86400), ' day');
  }
}

/* 数字 格式化*/
export function numberFormatter(num, digits) {
  const si = [
    { value: 1E18, symbol: 'E' },
    { value: 1E15, symbol: 'P' },
    { value: 1E12, symbol: 'T' },
    { value: 1E9, symbol: 'G' },
    { value: 1E6, symbol: 'M' },
    { value: 1E3, symbol: 'k' }
  ];
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (num / si[i].value + 0.1).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol;
    }
  }
  return num.toString();
}

export function toThousandFilter(num) {
  return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','));
}

export function dealNullData(val) {
  return val || '';
}
// 时间格式 年月日 1990/02/02
export function formatterDatenEn(time) {
  if (time == null || time === 'null' || time === null || time === undefined || time === 'undefined') {
    return '';
  } else {
    return moment(time).format('YYYY/MM/DD');
  }
}
// 时间格式 年月日 1990-02-02
export function formatterDateYMD(time) {
  if (time == null || time === 'null' || time === null || time === undefined || time === 'undefined') {
    return '';
  } else {
    // let date = new Date(time);
    // let m = date.getMonth() + 1;
    // let d = date.getDate();
    // m = m < 10 ? '0' + m : m;
    // d = d < 10 ? '0' + d : d;
    return moment(time).format('YYYY');
  }
}

export function formatterDate(time) {
  if (time == null || time === 'null' || time === null || time === undefined || time === 'undefined') {
    return '';
  } else {
    return moment(time).format('YYYY-MM-DD');
    // let date = new Date(time);
    // let m = date.getMonth() + 1;
    // let d = date.getDate();
    // m = m < 10 ? '0' + m : m;
    // d = d < 10 ? '0' + d : d;
    // return date.getFullYear() + '-' + m + '-' + d;
  }
}

export function formatterMonth(time) {
  if (time == null || time === 'null' || time === null || time === undefined || time === 'undefined') {
    return '';
  } else {
    let date = new Date(time);
    let m = date.getMonth() + 1;
    let d = date.getDate();
    m = m < 10 ? '0' + m : m;
    d = d < 10 ? '0' + d : d;
    return date.getFullYear() + '-' + m;
  }
}

export function returnYMD(time) {
  if (time == null || time === 'null' || time === null || time === undefined || time === 'undefined') {
    return '';
  } else {
    let date = new Date(time);
    let m = date.getMonth() + 1;
    let d = date.getDate();
    // m = m < 10 ? '0' + m : m;
    // d = d < 10 ? '0' + d : d;
    return {
      year: date.getFullYear(),
      month: m,
      day: d
    };
  }
}

export function hideName(pName) {
  let name = pName ? pName.replace(/(^\s*)|(\s*$)/g, '') : '';
  let newName = '';
  if (name) {
    if (name.length === 2) {
      newName = '*' + name[1];
    } else {
      newName = name[0] + '*' + name[name.length - 1];
    }
    return newName;
  } else {
    return name;
  }
}
